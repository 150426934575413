import styled from "styled-components";

export const Container = styled.div`
  color: ${(p) => (p.color ? p.color : p.theme.colors.text.light)};
  font-size: ${({ defaultSize, fontBase }) =>
    fontBase ? 1 * fontBase : 1 * defaultSize}em;
  font-family: "FARRAY Regular";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > span {
    font-size: 2em;
    font-weight: bold;
  }
`;
