import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 20rem;

  & > img {
    width: 100%;
    object-fit: contain;
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    max-width: 60rem;
    min-height: 20rem;
  }
`;

export const ButtonUpload = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.text.dark};
  gap: 1rem;
  padding: 0.5rem 1rem;
  font-family: "Montserrat SemiBold", sans-serif;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 1.8rem;
  line-height: normal;
  background-color: ${(p) => p.theme.colors.light[100]}80;
  border: none;
  & > input {
    display: none;
  }
`;

export const ButtonFirstUpload = styled.label`
  width: 100%;
  padding-top: 56.25%;
  border: 1px solid ${(p) => p.theme.colors.primary.default};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.text.dark};
  gap: 1rem;
  font-family: "Montserrat SemiBold", sans-serif;
  position: relative;
  cursor: pointer;
  font-size: 1.8rem;
  line-height: normal;
  background-color: ${(p) => p.theme.colors.light[100]}80;

  & > span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  & > input {
    display: none;
  }
`;
