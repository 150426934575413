import { saveLog } from "services/logs";
import firebase from "./FirebaseConfig";

const auth = firebase.auth;

const BASE_URL = `${process.env.REACT_APP_CLOUD_FUNCTION_API_URL}/v1`;

const createDocument = async (collection, document) => {
  let token;

  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    console.log("error", error.message);
    throw error;
  }

  try {
    const response = await fetch(`${BASE_URL}/${collection}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(document),
    });

    if (response.status !== 201) {
      const errorMessage = await response.text();
      const error = { message: errorMessage };

      throw error;
    }

    return response.json();
  } catch (error) {
    console.log(
      "FirebaseFirestoreRestService.createDocument error",
      error.message
    );
    throw error;
  }
};

const readDocuments = async ({
  collection,
  queries,
  orderByField,
  orderByDirection,
  perPage,
  pageNumber,
}) => {
  try {
    const url = new URL(`${BASE_URL}/${collection}`);

    for (const query of queries) {
      url.searchParams.append(query.field, query.value);
    }

    if (orderByField) {
      url.searchParams.append("orderByField", orderByField);
    }

    if (orderByDirection) {
      url.searchParams.append("orderByDirection", orderByDirection);
    }

    if (perPage) {
      url.searchParams.append("perPage", perPage);
    }

    if (pageNumber) {
      url.searchParams.append("pageNumber", pageNumber);
    }

    let token;

    try {
      token = await auth.currentUser.getIdToken();
    } catch (error) {
      // continue.
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 200) {
      const errorMessage = await response.text();
      const error = { message: errorMessage };

      throw error;
    }

    return response.json();
  } catch (error) {
    console.log(
      "FirebaseFirestoreRestService.readDocuments error",
      error.message
    );
    throw error;
  }
};

const readDocument = async (collection, id) => {
  try {
    if (!id) {
      throw new Error("eventId must be provide");
    }
    const url = new URL(`${BASE_URL}/${collection}/${id}`);

    let token;

    try {
      token = await auth.currentUser.getIdToken();
    } catch (error) {
      // continue.
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 200) {
      const errorMessage = await response.text();
      const error = { message: errorMessage };

      throw error;
    }

    return response.json();
  } catch (error) {
    console.log(
      "FirebaseFirestoreRestService.readDocument error",
      error.message
    );
    throw error;
  }
};

const updateDocument = async (collection, id, document) => {
  let token;

  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    console.log("error", error.message);
    throw error;
  }

  try {
    const response = await fetch(`${BASE_URL}/${collection}/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(document),
    });

    if (response.status !== 200) {
      const errorMessage = await response.text();
      const error = { message: errorMessage };

      throw error;
    }

    return response.json();
  } catch (error) {
    console.log(
      "FirebaseFirestoreRestService.updateDocument error",
      error.message
    );
    throw error;
  }
};

const deleteDocument = async (collection, id) => {
  let token;

  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    console.log("error", error.message);
    throw error;
  }

  try {
    const response = await fetch(`${BASE_URL}/${collection}/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 204) {
      const errorMessage = await response.text();
      const error = { message: errorMessage };

      throw error;
    }
    return response;
  } catch (error) {
    console.log(
      "FirebaseFirestoreRestService.deleteDocument error",
      error.message
    );
    throw error;
  }
};

const createPayment = async ({ payment, logData }) => {
  let token;

  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    console.log("error", error.message);
    throw error;
  }

  try {
    await saveLog({
      ...logData,
    });
  } catch (error) {
    console.log("savelog.error", error.message);
  }

  try {
    const response = await fetch(
      `${BASE_URL}/stripeTest/create-checkout-session`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payment),
      }
    );

    if (response.status !== 303) {
      const errorMessage = await response.text();
      const error = { message: errorMessage };

      throw error;
    }

    return response.json();
  } catch (error) {
    console.log(
      "FirebaseFirestoreRestService.createPayment error",
      error.message
    );
    throw error;
  }
};

const createGiftCardPayment = async (payment) => {
  try {
    await saveLog({
      action: "click-payment",
      giftCard: payment?.giftCardId,
      data: {
        payment: "paid-default",
        buyerEmail: payment?.customerEmail,
        price: payment?.price,
      },
    });
  } catch (error) {
    console.log("savelog.error", error.message);
  }

  try {
    const response = await fetch(
      `${BASE_URL}/stripeTest/create-gift-card-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payment),
      }
    );

    if (response.status !== 303) {
      const errorMessage = await response.text();
      const error = { message: errorMessage };

      throw error;
    }

    return response.json();
  } catch (error) {
    console.log(
      "FirebaseFirestoreRestService.createPayment error",
      error.message
    );
    throw error;
  }
};

const getCheckoutSession = async (sessionId) => {
  let token;

  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    console.log("error", error.message);
    throw error;
  }

  try {
    const response = await fetch(
      `${BASE_URL}/stripeTest/checkout-session/${sessionId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      const errorMessage = await response.text();
      const error = { message: errorMessage };

      throw error;
    }
    return response.json();
  } catch (error) {
    console.log("error", error.message);
    throw error;
  }
};

const sendEmail = async (data) => {
  let token;

  try {
    token = await auth.currentUser.getIdToken();
  } catch (error) {
    console.log(
      "FirebaseFirestoreRestService.getCheckoutSession error",
      error.message
    );
    throw error;
  }

  /**
   * Email Types:
   * 
    NEW_EVENT: new event created,
    PAYMENT_SUCCESSFUL: when payment is successful,
    INVITE_NEW_USER: when invited a new user,
    INVITE_EXISTING_USER: when invited a existing user,
    NEW_VIDEOS_ONE_MONTH: when new videos are released one month after the event,
    NEW_VIDEOS_SIX_MONTHS: when new videos are released six months after the event,
    NEW_VIDEOS_ONE_YEAR: when new videos are released one year after the event,
   */

  const message = {
    to: data.email,
    template_id: data.emailType,
    template_data: {
      first_name: data.name,
      button_link: data.buttonLink,
    },
  };

  try {
    const response = await fetch(`${BASE_URL}/email`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    });

    if (response.status !== 200) {
      const errorMessage = await response.text();
      const error = { message: errorMessage };

      throw error;
    }
    return response.json();
  } catch (error) {
    console.log("FirebaseFirestoreRestService.sendEmail error", error.message);
    throw error;
  }
};

const FirebaseFirestoreRestService = {
  createDocument,
  readDocument,
  readDocuments,
  updateDocument,
  deleteDocument,
  createPayment,
  createGiftCardPayment,
  getCheckoutSession,
  sendEmail,
};

export default FirebaseFirestoreRestService;
