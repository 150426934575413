export const messages = {
  "en-US": {
    "app.logout": "Logout",
    "app.loading": "Loading...",
    "app.loadMore": "Load more",
    "app.noMoreRecords": "No more records",
    "app.noRecordsFound": "No records found",
    "app.finishingUpload": "Finishing upload...",
    "app.adjustImage": "Adjust image",
    "app.playing": "Playing...",
    "date.format": "MM/dd/yyyy",
    "date.placeholder": "Select a date",

    "events.title": "Events",
    "event.new": "New Event",
    "event.edit": "Edit",
    "event.date": "Event Date",
    "event.code": "Event Code",
    "event.recipients": "Recipients",
    "event.link": "Event Link",
    "event.qrCode": "QR Code",
    "event.price": "Price",
    "event.pay.now": "Pay now",
    "events.shared.you": "Shared with you",
    "event.notFound": "Event not found or you don't have access to it.",
    "event.notEditable": "This event is not editable anymore.",

    "payment.canceled": "Payment not completed",
    "payment.success": "Payment completed successfully",

    "videos.open": "Open Capsules",

    "title.event": "Event",
    "title.forgotPassword": "Forgot Password",
    "title.whoReceivedVideos": "Who will receive the videos?",
    "title.dontHaveAccount": "Don't have an account?",
    "title.checkYourEmail": "Check your email",
    "title.dontReceiveEmail": "Didn't receive the email?",
    "title.login": "Login",
    "title.messageNewlyweds": "Record a Message!",
    "title.filesToPrint": "Files to Print",
    "title.alreadyHaveAccount": "Already have an account?",
    "title.newAccount": "New Account",
    // "title.firstDelivery": "First Delivery",
    // "title.secondDelivery": "Second Delivery",
    // "title.lastDelivery": "Last Delivery",
    "title.delivery.oneMonth": "First Delivery",
    "title.delivery.sixMonths": "Second Delivery",
    "title.delivery.oneYear": "Last Delivery",
    "title.delivery.recent": "Recent Recorded",
    // "title.summary": "Summary",
    "title.coupon.free": "Gift!",
    "title.coupon.discount": "Discount Coupon!",
    "title.done": "You are good to go!",
    "title.greeting": "Hey, {name}!",
    "title.createAnotherEvent": "Do you want to create another event?",
    "title.yourPurchase": "Your Purchase",
    "title.giftCard": "Gift Card <code>Cápsula do Tempo</code>",
    "title.yourData": "Your Data",
    "title.congratulations": "Congratulations!",
    "title.howItWorks": "How it works",
    "title.steps": "Steps",
    "title.giftCardNotFound": "Gift Card not found",
    "title.giftCardUsed": "Gift Card already used",
    "title.redeemGiftCard": "Enter the Gift Card code",
    "title.youAreLuck": "You are lucky!",
    "title.giftCard.ready": "Download your Gift Card",
    "title.sharedVideo": "Shared Video",
    "title.selectLanguage": "Select Language",
    "title.shareVideo": "Share Video",
    "title.downloadVideo": "Download Video",

    "description.checkYourEmail":
      "You must confirm your email to finish the registration.",
    "description.dontReceiveEmail":
      "Check your spam folder or click the button below to send the email again.",
    "description.qrCode": "Scan the QR Code to record a message",
    "description.coupon.free": "You got a free event!",
    "description.coupon.discount": "You have a coupon discount!",
    "description.plan.paid":
      "Pay now to access the event link to record video messages and print the QR Code.",
    "description.recorderLink":
      "If you prefer, you can access the link below to access the message recorder app.",
    "description.qrCodeLink":
      "Get your phone and scan the QR Code to record a message to yourself. It is a test, but you'll receive it in the future.",
    "description.shareLink":
      "Share the link with your friends and family. They can start recording messages now.",
    "description.giftCard":
      "Gift card referring to 1 'Cápsula do Tempo' event. The gift card can be used by you or by another person through a unique code.",
    "description.giftCardReady":
      "Your gift card is ready to be used. Now you can download it or share it.",
    "description.howItWorks.giftCard.one":
      "Cápsula do Tempo offers an innovative way to remember your wedding day with video messages recorded by your guests.",
    "description.howItWorks.giftCard.two":
      "Receive <codeOne>video messages</codeOne> recorded by your <codeTwo>guests</codeTwo> during your event, to be watched in the future!",
    "description.howItWorks.giftCard.three":
      "Through an exclusive QR code, your guests access the <code>Cápsula do Tempo</code> app and record videos of every moment of your party.",
    "description.howItWorks.giftCard.four":
      "The videos will be delivered on 3 future dates: 1 month after the event, 6 months, and on the 1-year anniversary.",
    "description.steps.giftCard.one": `Click on the 'Get Started' button at the end of this page and create your account on the platform.`,
    "description.steps.giftCard.two":
      "Then, click on 'Start' and follow the step-by-step instructions to create your event.",
    "description.steps.giftCard.three":
      "At the payment step, enter the code of the Gift Card you received in the 'I have a Gift Card' field.",
    "description.steps.giftCard.four":
      "Download and print the artwork to place on the tables, or share the QR code for your guests to record videos during the party.",
    "description.giftCardNotFound":
      "The Gift Card you are looking for was not found. If the problem persists, contact us.",
    "description.giftCardUsed":
      "The Gift Card you are looking for has already been used. If this is a problem, contact us.",
    "description.redeemGiftCard": "It's on the Gift Card you received.",
    "description.youAreLuck":
      "You entered through a partner link and can now create a <code>free</code> event!",
    "description.youAreLuckDiscount":
      "You entered through a partner link and can now create an event with a <code>special discount</code>!",
    "description.shareVideo":
      "Click to copy the link and share it with your friends",

    "label.title": "Title",
    "label.date": "Date",
    "label.name": "Name",
    "label.email": "Email",
    "label.password": "Password",
    "label.model": "Model",
    "label.phone": "Phone",
    "label.releaseDate": "Release Date",
    "label.autoplay": "Autoplay",
    // "label.exihitionTitle": "Event exibition title",
    "label.recorderLink": "Message Recorder Link",
    "label.qrCodeLink": "Your Event QR Code",
    "label.shareLink": "Share with your friends",
    "label.code": "Code",
    "label.from": "From",
    "label.for": "For",
    "label.videoMessages": "Video Messages",

    "wizard.step1": "Info",
    "wizard.step2": "Banner",
    // "wizard.step3": "Recipients",
    "wizard.step3": "Pagamento",
    "wizard.step4": "Done",

    "button.addRecipient": "Add recipient",
    "button.accessEvent": "Access Event",
    "button.add": "Add",
    "button.edit": "Edit",
    "button.create": "Create",
    "button.update": "Update",
    "button.cancel": "Cancel",
    "button.crop": "Crop",
    "button.goBack": "Go Back",
    "button.save": "Save",
    "button.delete": "Delete",
    "button.resetPassword": "Reset Password",
    "button.login": "Login",
    "button.signUp": "Sign Up",
    "button.forgotPassword": "Forgot Password",
    "button.loginWithGoogle": "Login with Google",
    "button.resendEmail": "Click to resend the confirmation email",
    "button.alreadyConfirmed": "Already confirmed!",
    "button.digital": "Digital",
    "button.placeCard": "Place Card",
    "button.finish": "Finish",
    "button.createWithGoogle": "Create with Google",
    "button.access": "Access",
    "button.on": "On",
    "button.off": "Off",
    "button.createEvent": "Create Event",
    "button.createFreeEvent": "Create Event for Free",
    // "button.createFirstEvent": "Create your first event",
    "button.coupon.free": "Get Gift",
    "button.coupon.discount": "Redeem Coupon",
    "button.next": "Next",
    "button.addBanner": "Add Banner",
    "button.payLater": "Pay Later",
    "button.payNow": "Pay Now",
    "button.payWithDiscount": "Pay with Discount",
    "button.start": "Start!",
    "button.share": "Share",
    "button.download": "Download",
    "button.clickToCreate": "Click to create",
    "button.goToPayment": "Go to Payment",
    "button.tryAgain": "Try Again",
    "button.goToHome": "Go to Home",
    "button.startNow": "Get Started",
    "button.haveGiftCard": "I have a Gift Card",
    "button.dontHaveGiftCard": "I don't have a Gift Card",
    "button.redeem": "Redeem",
    "button.copied": "Copied!",

    "error.userNotFound.passwordIncorrect":
      "User not found or password incorrect",
    "error.dateGreaterOrEqualToday": "The date must be greater or equal today",
    "error.required": "Required",
    "error.invalidGiftCode": "Invalid Gift Card Code or already used",
    "error.dateGreaterOrEqualOneMonthLater":
      "Date must be greater than 1 month after the event",
    "error.dateLessOrEqualFiveYearsLater":
      "Date must be less than 5 years after the event",

    // "message.createYourFirstEvent": "Create your first event!",
    "message.noEventsYet": "You don't have any events yet.",
    "message.videos.scheduled": "Wow, your time capsules are scheduled!",
    "message.videos.readyToWatch":
      "Wow, your time capsules are ready to watch!",
    "message.event.ready":
      "Now your event is active and ready to get messages!",
    "message.event.ready.description":
      "Share the event so your guests can record messages and print the exclusive QR Code to access the event.",
    "message.event.notHeld": "You were invited to an awsome event!",
    "message.event.notHeld.description":
      "Share the event link so the guests can record messages.",
    "message.event.terms":
      "By creating an event, you agree to the <code>terms of use and privacy policy</code>.",
    "message.payment.canceled":
      "Your payment was cancelled. Try again. If the problem persists, contact us.",
    "message.payment.canceled.options":
      "You can try again with another card or return to the home page.",
    "message.payment.success":
      "We received the payment for {title} event. Now you can access the information about the event.",
    "message.daysRemainingToRelease":
      "{count, plural, one {<code># day</code>} other {<code># days</code>}} remaining to release.",
    "message.notCompatibleSafari":
      "This is a beta feature and is not compatible with Safari yet. Please use Chrome or Firefox.",
    // "message.deliveryDate":
    //  "All messages recorded up to the date of the event will be automatically distributed and released on the dates below.",
    "message.coupon.free":
      "This gift is unique and can only be redeemed once. When redeeming this gift, it will be applied to this event, and it will no longer be possible to reuse it.",
    "message.coupon.discount":
      "This coupon is unique and can only be redeemed once. When redeeming this coupon, it will be applied to this event, and it will no longer be possible to reuse it, even if the payment is not completed.",
    "message.addBannerLater": "You can also add a banner later.",
    "message.addRecipientLater": "You can also add recipients later.",
    "message.done":
      "You are ready to receive video messages. Use the methods below to try it out.",
    "message.startOne":
      "We prepared an super easy wizard to create your first event.",
    "message.startTwo": "Click on the button below to start!",
    "message.shareLink":
      "Hey, there is a Time Capsule in my event! Record a video message and I'll receive it in the future.",
    "message.softLaunch":
      "You got a Time Capsule for your event! Take the opportunity to make your event unforgettable and share it with your friends and family.",
    "message.giftCardPayment":
      "After payment confirmation, you will receive a link in your email to access, download, and share your Gift Card.",
    "message.giftCardReady":
      "When the newlyweds receive this gift card, they can read the QR code with their cell phone to start setting up the event.",
    "message.congratulations.giftCard":
      "You have been gifted a <code>Cápsula do Tempo</code>.",
    "message.awesome.giftCard":
      "Now your event will be even more amazing! The Time Capsule will help you capture and treasure this very special moment in your lives!",
    "message.downloadVideo": "We are preparing your video. Wait a moment...",
    "message.sharedVideo": "Hey, I shared a video with you!",
    "message.releaseDate":
      "The release date must be greater or equal {minDate} and less or equal {maxDate}.",
  },
  "pt-BR": {
    "app.logout": "Sair",
    "app.loading": "Carregando...",
    "app.loadMore": "Carregar mais",
    "app.noMoreRecords": "Não há mais registros",
    "app.noRecordsFound": "Nenhum registro encontrado",
    "app.finishingUpload": "Finalizando o upload...",
    "app.adjustImage": "Ajustar imagem",
    "app.playing": "Reproduzindo...",
    "date.format": "dd/MM/yyyy",
    "date.placeholder": "DD/MM/AAAA",

    "events.title": "Eventos",
    "event.new": "Novo Evento",
    "event.edit": "Editar",
    "event.date": "Data do evento",
    "event.code": "Código do evento",
    "event.recipients": "Destinatários",
    "event.link": "Link do evento",
    "event.qrCode": "QR Code",
    "event.price": "Valor",
    "event.pay.now": "Pagar agora",
    "events.shared.you": "Pra você assistir",
    "event.notFound": "Evento não encontrado ou você não tem acesso a ele.",
    "event.notEditable":
      "Evento com data anterior a data atual não pode ser editado.",

    "payment.canceled": "Pagamento não realizado",
    "payment.success": "Pagamento realizado com sucesso",

    "videos.open": "Acessar Cápsulas",

    "title.event": "Evento",
    "title.forgotPassword": "Esqueci minha senha",
    "title.whoReceivedVideos": "Quem receberá os vídeos?",
    "title.dontHaveAccount": "Não possui conta?",
    "title.checkYourEmail": "Verifique seu email",
    "title.dontReceiveEmail": "Não recebeu o email?",
    "title.login": "Entrar",
    "title.messageNewlyweds": "Mensagem aos Noivos!",
    "title.filesToPrint": "Arquivos para impressão",
    "title.alreadyHaveAccount": "Já possui uma conta?",
    "title.newAccount": "Nova Conta",
    // "title.firstDelivery": "Primeira entrega",
    // "title.secondDelivery": "Segunda entrega",
    // "title.lastDelivery": "Última entrega",
    "title.delivery.oneMonth": "Primeira entrega",
    "title.delivery.sixMonths": "Segunda entrega",
    "title.delivery.oneYear": "Última entrega",
    "title.delivery.recent": "Recentemente gravados",
    // "title.summary": "Resumo",
    "title.coupon.free": "Presente!",
    "title.coupon.discount": "Cupom de Desconto!",
    "title.done": "Tudo pronto!",
    "title.greeting": "Olá, {name}!",
    "title.createAnotherEvent": "Quer criar outro evento?",
    "title.yourPurchase": "Sua compra",
    "title.giftCard": "Gift Card <code>Cápsula do Tempo</code>",
    "title.yourData": "Seus dados",
    "title.congratulations": "Parabéns!",
    "title.howItWorks": "Como funciona?",
    "title.steps": "Passo a passo",
    "title.giftCardNotFound": "Gift Card não encontrado",
    "title.giftCardUsed": "Gift Card já utilizado",
    "title.redeemGiftCard": "Informe o código do Gift Card",
    "title.youAreLuck": "Você está com sorte!",
    "title.giftCard.ready": "Baixe seu Gift Card",
    "title.sharedVideo": "Vídeo compartilhado",
    "title.selectLanguage": "Selecione o idioma",
    "title.shareVideo": "Compartilhe seu vídeo",
    "title.downloadVideo": "Baixar vídeo",

    "description.checkYourEmail":
      "Você deve confirmar seu email para finalizar o cadastro.",
    "description.dontReceiveEmail":
      "Verifique sua caixa de spam ou clique no botão abaixo para reenviar o email.",
    "description.qrCode": "Leia o QR Code e grave uma mensagem",
    "description.coupon.free": "Você ganhou um evento de presente.",
    "description.coupon.discount":
      "Você possui um cupom para resgatar um desconto.",
    "description.plan.paid":
      "Pague agora para liberar o acesso ao link de gravação de vídeo mensagens do evento e ao QR Code para impressão.",
    "description.recorderLink":
      "Você também pode usar o link abaixo para acessar o app de gravação de mensagens.",
    "description.qrCodeLink":
      "Use seu celular para ler o QR Code e gravar uma mensagem. Pode ser um teste, mas você vai receber esse vídeo no futuro.",
    "description.shareLink":
      "Compartilhe com seus amigos. Eles já podem gravar uma mensagem agora.",
    "description.giftCard":
      "Vale Presente referente a 1 evento da Cápsula do Tempo. O Vale Presente pode ser usado por você ou por outra pessoa por meio de um código único.",
    "description.giftCardReady":
      "Seu Gift Card está pronto! Você já pode baixar e compartilhar.",
    "description.howItWorks.giftCard.one":
      "A Cápsula do Tempo entrega uma  forma inovadora de reviver o dia do seu casamento com vídeo mensagens gravadas pelos seus convidados.",
    "description.howItWorks.giftCard.two":
      "Receba no futuro, <codeOne>mensagens em vídeo</codeOne> gravadas pelos seus <codeTwo>convidados</codeTwo> durante o seu evento!",
    "description.howItWorks.giftCard.three":
      "Por meio de um QR-Code exclusivo, seus convidados acessam o app da <code>Cápsula do Tempo</code> e gravam vídeos de todos os momentos da sua festa. ",
    "description.howItWorks.giftCard.four":
      "Os vídeos serão entregues em 3 datas futuras: 1 mês após o evento, 6 meses e nas bodas de 1 ano.",
    "description.steps.giftCard.one": `Clique no botão ”Comece agora" no final desta página e crie sua conta na plataforma.`,
    "description.steps.giftCard.two": `Em seguida, clique em "Começar" e siga o passo a passo para criar seu evento.`,
    "description.steps.giftCard.three": `Na etapa de pagamento, insira o código do Gift Card que você recebeu no campo "Tenho um Gift Card”.`,
    "description.steps.giftCard.four": `Baixe e imprima a arte para colocar nas mesas ou compartilhe o QR-Code para seus convidados gravarem vídeos durante a festa.`,
    "description.giftCardNotFound":
      "O Gift Card que você procura não foi encontrado. Se o problema persistir, entre em contato conosco.",
    "description.giftCardUsed":
      "O Gift Card que você procura já foi utilizado. Se isto for um problema, entre em contato conosco.",
    "description.redeemGiftCard": "Ele está no Gift Card que você recebeu.",
    "description.youAreLuck":
      "Você entrou pelo link de um parceiro e pode criar um evento <code>gratuito</code> agora!",
    "description.youAreLuckDiscount":
      "Você entrou pelo link de um parceiro e pode obter um evento com <code>desconto especial</code> agora!",
    "description.shareVideo":
      "Clique para copiar o link do vídeo e compartilhe com seus amigos.",

    "label.title": "Título",
    "label.date": "Data",
    "label.name": "Nome",
    "label.email": "Email",
    "label.password": "Senha",
    "label.model": "Modelo",
    "label.phone": "Telefone",
    "label.releaseDate": "Data de liberação",
    "label.autoplay": "Reprodução automática",
    // "label.exihitionTitle": "Nome de exibição do evento",
    "label.recorderLink": "Link para gravar mensagem",
    "label.qrCodeLink": "QR Code do seu evento",
    "label.shareLink": "Compartilhe com seus amigos",
    "label.code": "Código",
    "label.from": "De",
    "label.for": "Por",
    "label.videoMessages": "Vídeo mensagens",

    "wizard.step1": "Dados",
    "wizard.step2": "Banner",
    // "wizard.step3": "Destinatários",
    "wizard.step3": "Pagamento",
    "wizard.step4": "Pronto",

    "button.addRecipient": "Adicionar destinatário",
    "button.accessEvent": "Acessar evento",
    "button.add": "Adicionar",
    "button.edit": "Editar",
    "button.create": "Criar",
    "button.update": "Alterar",
    "button.cancel": "Cancelar",
    "button.crop": "Cortar",
    "button.goBack": "Voltar",
    "button.delete": "Excluir",
    "button.save": "Salvar",
    "button.resetPassword": "Redefinir senha",
    "button.login": "Entrar",
    "button.signUp": "Criar Conta",
    "button.forgotPassword": "Esqueci minha senha",
    "button.loginWithGoogle": "Entrar com Google",
    "button.resendEmail": "Clique aqui para reenviar o email de confirmação",
    "button.alreadyConfirmed": "Já confirmei!",
    "button.digital": "Digital",
    "button.placeCard": "Cartão de Mesa",
    "button.finish": "Concluir",
    "button.createWithGoogle": "Criar com Google",
    "button.access": "Acessar",
    "button.on": "Ligado",
    "button.off": "Desligado",
    "button.createEvent": "Criar evento",
    "button.createFreeEvent": "Criar evento gratuito",
    // "button.createFirstEvent": "Criar Primeiro Evento",
    "button.coupon.free": "Resgatar Presente",
    "button.coupon.discount": "Resgatar Cupom",
    "button.next": "Próximo",
    "button.addBanner": "Adicionar Banner",
    "button.payLater": "Pagar depois",
    "button.payNow": "Pagar agora",
    "button.start": "Começar!",
    "button.share": "Compartilhar",
    "button.download": "Baixar",
    "button.clickToCreate": "Clique para criar",
    "button.goToPayment": "Ir para o pagamento",
    "button.tryAgain": "Tentar novamente",
    "button.goToHome": "Ir para a página inicial",
    "button.startNow": "Comece agora",
    "button.haveGiftCard": "Tenho um Gift Card",
    "button.dontHaveGiftCard": "Não tenho um Gift Card",
    "button.redeem": "Resgatar",
    "button.payWithDiscount": "Pagar com desconto",
    "button.copied": "Copiado!",

    "error.userNotFound.passwordIncorrect":
      "Usuário não encontrado ou senha incorreta",
    "error.dateGreaterOrEqualToday": "A data deve ser maior ou igual a hoje",
    "error.required": "Obrigatório",
    "error.invalidGiftCode": "Código inválido ou Gift Card já utilizado",
    "error.dateGreaterOrEqualOneMonthLater":
      "A data deve ser maior que 1 mês após a data do evento",
    "error.dateLessOrEqualFiveYearsLater":
      "A data deve ser menor que 5 anos após a data do evento",

    // "message.createYourFirstEvent": "Crie o seu primeiro!",
    "message.noEventsYet": "Você ainda não possui nenhum evento.",
    "message.videos.scheduled": "Uau, suas cápsulas estão agendadas!",
    "message.videos.readyToWatch":
      "Uau, você tem cápsulas prontas para assistir!",
    "message.event.ready":
      "Agora seu evento está ativo e pronto para receber vídeos.",
    "message.event.ready.description":
      "Compartilhe o link do evento para que seus convidados possam gravar mensagens e imprima o QR Code exclusivo do seu evento.",
    "message.event.notHeld": "Você faz parte de um incrível evento!",
    "message.event.notHeld.description":
      "Compartilhe o link do evento para que os convidados comecem a gravar vídeo mensagens.",

    "message.event.terms":
      "Ao criar um evento, você concorda com os nossos <code>termos de uso e política de privacidade</code>.",
    "message.payment.canceled":
      "Seu pagamento foi cancelado. Tente novamente. Se o problema persistir, entre em contato.",
    "message.payment.canceled.options":
      "Você pode tentar novamente com outro cartão ou retornar à página inicial.",
    "message.payment.success":
      "Recebemos o pagamento para o evento <code>{title}</code> com sucesso! Agora você já pode acessar as informações sobre o seu evento.",
    "message.daysRemainingToRelease":
      "{count, plural, one {<code># dia</code>} other {<code># dias</code>}} restantes para a abertura.",
    "message.notCompatibleSafari":
      "Esta é uma funcionalidade em teste e o navegador Safari não é compatível por enquanto. Tente usar o Google Chrome ou Mozilla Firefox.",
    // "message.deliveryDate":
    //  "Todas as mensagens gravadas até a data do evento serão distribuídas automaticamente e liberadas nas datas abaixo.",
    "message.coupon.free":
      "Este presente é único e só pode ser resgatado uma vez. Ao resgatar este presente, ele será aplicado a este evento, não sendo mais possível reutilizá-lo.",
    "message.coupon.discount":
      "Este cupom é único e só pode ser resgatado uma vez. Ao resgatar este cupom, ele será aplicado a este evento, não sendo mais possível reutilizá-lo, mesmo que o pagamento não seja concluído.",
    "message.addBannerLater": "Você também pode adicionar o banner depois.",
    "message.addRecipientLater":
      "Você também pode adicionar os destinatários depois.",
    "message.done":
      "Seu evento está pronto para receber mensagens. Use um dos métodos abaixo para testar.",
    "message.startOne":
      "Preparamos um passo a passo super fácil para criar seu primeiro evento.",
    "message.startTwo": "Clique no botão abaixo para começar!",
    "message.shareLink":
      "Hey, tenho uma Cápsula do Tempo no meu evento. Grave uma mensagem e eu vou receber no futuro!",
    "message.softLaunch":
      "Você recebeu uma Cápsula do Tempo para o seu evento! Aproveite para tornar seu evento inesquecível e compartilhe com seus amigos e familiares.",
    "message.giftCardPayment":
      "Após a confirmação do pagamento, você receberá um link em seu e-mail para acessar, baixar e compartilhar seu Gift Card.",
    "message.giftCardReady":
      "Quando os noivos receberem este Gift Card, eles poderão ler este QR-Code com o celular e configurar o evento imediatamente.",
    "message.congratulations.giftCard":
      "Você foi presenteado com uma <code>Cápsula do Tempo</code>.",
    "message.awesome.giftCard":
      "Agora seu evento será ainda mais incrível! A <code>Cápsula do Tempo</code> os ajudará a eternizar esse momento tão especial na vida de vocês!",
    "message.downloadVideo":
      "Estamos preparando seu vídeo para download. Aguarde alguns instantes.",
    "message.sharedVideo": "Hey, compartilhei um vídeo com você!",
    "message.releaseDate":
      "A data de liberação deve ser maior ou igual a {minDate} e menor ou igual a {maxDate}.",
  },
};
