import Logo from "components/Logo";
import Spinner from "components/Spinner";
import GlobalContext from "context";
import { useCallback, useContext, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useUpdateEvent } from "services/events";
import { getGiftCardByCode, useUpdateGiftCard } from "services/giftCards";
import { saveLog } from "services/logs";

const { Container, ButtonElement } = require("./styles");

const RedeemGiftCard = ({ event }) => {
  const { user } = useContext(GlobalContext);
  const [giftCode, setGiftCode] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const { mutate: updateGiftCard, isLoading: isLoadingUpdateGiftCard } =
    useUpdateGiftCard();
  const { mutate: updateEvent, isLoading: isLoadingUpdateEvent } =
    useUpdateEvent();

  const onGiftCodeChange = (e) => {
    setError(false);
    setGiftCode(e.target.value);
  };

  const isLoading = useMemo(() => {
    return isLoadingUpdateGiftCard || isLoadingUpdateEvent;
  }, [isLoadingUpdateGiftCard, isLoadingUpdateEvent]);

  const onRedeemHandler = useCallback(async () => {
    if (giftCode.length === 6) {
      const giftCard = await getGiftCardByCode(giftCode);

      if (!giftCard) {
        setError(true);
      }
      if (giftCard) {
        const updatedEventData = {
          ...event,
          id: event.id,
          payment: "giftCard",
          status: "published",
          giftCardId: giftCard.id,
          giftCardCode: giftCard.code,
        };
        updateEvent(updatedEventData, {
          onSuccess: () => {
            const updatedGiftCardData = {
              ...giftCard,
              id: giftCard.id,
              status: "used",
            };
            updateGiftCard(updatedGiftCardData, {
              onSuccess: () => {
                saveLog({
                  action: "redeem-giftcard",
                  event: event?.id,
                  giftCard: giftCard?.id,
                  user: user?.id,
                  data: {
                    payment: "giftCard",
                    giftCardCode: giftCard?.code,
                    buyerEmail: giftCard?.buyerEmail,
                    buyerName: giftCard?.buyerName,
                    eventTitle: event?.title,
                    eventDate: event?.date,
                    eventCode: event?.code,
                    eventStatus: "published",
                    userEmail: user?.email,
                    userName: user?.name,
                    userOwner: event?.userOwner,
                    userOwnerEmail: event?.userOwnerEmail,
                    userOwnerName: event?.userOwnerName,
                  },
                });
                navigate(`/events/paymentSuccess/${event.id}`);
              },
            });
          },
        });
      }
    }
  }, [giftCode, event, updateEvent, updateGiftCard, navigate, user]);

  return (
    <Container>
      <Logo color="#222" fontBase="2" />
      <h3>
        <FormattedMessage id="title.redeemGiftCard" />
      </h3>
      <p>
        <FormattedMessage id="description.redeemGiftCard" />
      </p>
      <label htmlFor="giftCode">
        <FormattedMessage id="label.code" />
        <input
          value={giftCode}
          onChange={onGiftCodeChange}
          type="text"
          name="giftCode"
        />
        {error && (
          <span>
            <FormattedMessage id="error.invalidGiftCode" />
          </span>
        )}
      </label>
      <ButtonElement type="button" onClick={onRedeemHandler}>
        {isLoading ? <Spinner /> : <FormattedMessage id="button.redeem" />}
      </ButtonElement>
    </Container>
  );
};
export default RedeemGiftCard;
