import Button from "components/Button";
import { useState } from "react";
import Cropper from "react-easy-crop";
import { FormattedMessage } from "react-intl";
import { Container, CropperContainer, ControlsContainer } from "./styles";

function ImageCropper({ image, onCropDone, onCropCancel }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onZoomChange = (e) => {
    setZoom(e.target.value);
  };

  return (
    <Container>
      <CropperContainer>
        <Cropper
          image={image}
          aspect={16 / 9}
          crop={crop}
          zoom={zoom}
          maxZoom={2}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      </CropperContainer>
      <ControlsContainer>
        <input
          type="range"
          min={1}
          max={2}
          step={0.01}
          value={zoom}
          onChange={onZoomChange}
        />
        <Button onClick={onCropCancel}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button
          onClick={() => {
            onCropDone(croppedArea);
          }}
          type="button"
        >
          <FormattedMessage id="button.crop" />
        </Button>
      </ControlsContainer>
    </Container>
  );
}

export default ImageCropper;
