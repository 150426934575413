import Loading from "components/Loading";
import ComponentToPreview from "modules/print/components/ComponentToPreview";
import ModelOne from "modules/print/components/ModelOne";
import { useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { useGetEventById } from "services/events";
import { Container, ModelList, ModelListItem, ButtonArea } from "./styles";
import * as htmlToImage from "html-to-image";
import ComponentToImage from "modules/print/components/ComponentToImage";
import ModelTwo from "modules/print/components/ModelTwo";
import ModelThree from "modules/print/components/ModelThree";
import ComponentToPreviewLandscape from "modules/print/components/ComponentToPreviewLandscape";
import ComponentToImageLandscape from "modules/print/components/ComponentToImageLandscape";
import ComponentToPrint from "modules/print/components/ComponentToPrint";
import ComponentToPrintLandscape from "modules/print/components/ComponentToPrintLandscape";
import { StyledMessage } from "libs/styled-components/styles";
import { FormattedMessage } from "react-intl";
import { saveLog } from "services/logs";
import GlobalContext from "context";

const Print = () => {
  const { user } = useContext(GlobalContext);
  const params = useParams();
  const modelOne = useRef();
  const modelTwo = useRef();
  const modelThree = useRef();
  const printModelOne = useRef();
  const printModelTwo = useRef();
  const printModelThree = useRef();

  const { data: event, isLoading } = useGetEventById(params.id);

  const generateImage = async ({ component, title, orientation, type }) => {
    let width = 1240;
    let height = 1754;
    if (type === "print") {
      width = 2480;
      height = 3508;
    }
    if (orientation === "landscape") {
      width = 1754;
      height = 1240;
      if (type === "print") {
        width = 3508;
        height = 2480;
      }
    }

    const dataURL = await htmlToImage.toPng(component, {
      canvasWidth: width,
      canvasHeight: height,
      width: width,
      height: height,
    });
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = `${title}.png`;
    link.click();
    link.remove();
    saveLog({
      action: "download-qrcode",
      event: event?.id,
      user: user?.id,
      data: {
        imageTitle: title,
        eventTitle: event?.title,
        eventDate: event?.date,
        eventCode: event?.code,
        eventStatus: event?.status,
        userOwner: event?.userOwner,
        userOwnerEmail: event?.userOwnerEmail,
        userOwnerName: event?.userOwnerName,
        userEmail: user?.email,
        userName: user?.name,
      },
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!event) {
    return (
      <StyledMessage>
        <h3>
          <FormattedMessage id="event.notFound" />
        </h3>
      </StyledMessage>
    );
  }
  return (
    <Container>
      <header>
        <h1>
          <FormattedMessage id="title.filesToPrint" /> [Beta]
        </h1>
        <p>
          <FormattedMessage id="message.notCompatibleSafari" />
        </p>
      </header>
      <ModelList>
        <ModelListItem>
          <h2>
            <FormattedMessage id="label.model" /> 1
          </h2>
          <ButtonArea>
            <button
              onClick={() =>
                generateImage({
                  component: modelOne.current,
                  title: "digital-modelo-1",
                })
              }
            >
              <FormattedMessage id="button.digital" />
            </button>
            <button
              onClick={() =>
                generateImage({
                  component: printModelOne.current,
                  title: "impressao-modelo-1",
                  type: "print",
                })
              }
            >
              <FormattedMessage id="button.placeCard" />
            </button>
          </ButtonArea>
          <ComponentToPreview>
            <ModelOne title={event.title} code={event.code} />
          </ComponentToPreview>
        </ModelListItem>
        <ModelListItem>
          <h2>
            <FormattedMessage id="label.model" /> 2
          </h2>
          <ButtonArea>
            <button
              onClick={() =>
                generateImage({
                  component: modelTwo.current,
                  title: "digital-modelo-2",
                })
              }
            >
              <FormattedMessage id="button.digital" />
            </button>
            <button
              onClick={() =>
                generateImage({
                  component: printModelTwo.current,
                  title: "impressao-modelo-2",
                  type: "print",
                })
              }
            >
              <FormattedMessage id="button.placeCard" />
            </button>
          </ButtonArea>
          <ComponentToPreview>
            <ModelTwo code={event.code} />
          </ComponentToPreview>
        </ModelListItem>
        <ModelListItem>
          <h2>
            <FormattedMessage id="label.model" /> 3
          </h2>
          <ButtonArea>
            <button
              onClick={() =>
                generateImage({
                  component: modelThree.current,
                  title: "digital-modelo-3",
                  orientation: "landscape",
                })
              }
            >
              <FormattedMessage id="button.digital" />
            </button>
            <button
              onClick={() =>
                generateImage({
                  component: printModelThree.current,
                  title: "impressao-modelo-3",
                  type: "print",
                  orientation: "landscape",
                })
              }
            >
              <FormattedMessage id="button.placeCard" />
            </button>
          </ButtonArea>

          <ComponentToPreviewLandscape>
            <ModelThree code={event.code} title={event.title} />
          </ComponentToPreviewLandscape>
        </ModelListItem>
      </ModelList>
      <ComponentToImage ref={modelOne}>
        <ModelOne title={event.title} code={event.code} />
      </ComponentToImage>
      <ComponentToImage ref={modelTwo}>
        <ModelTwo code={event.code} />
      </ComponentToImage>
      <ComponentToImageLandscape ref={modelThree}>
        <ModelThree title={event.title} code={event.code} />
      </ComponentToImageLandscape>

      <ComponentToPrint ref={printModelOne}>
        <ModelOne title={event.title} code={event.code} />
      </ComponentToPrint>
      <ComponentToPrint ref={printModelTwo}>
        <ModelTwo code={event.code} />
      </ComponentToPrint>
      <ComponentToPrintLandscape ref={printModelThree}>
        <ModelThree title={event.title} code={event.code} />
      </ComponentToPrintLandscape>
    </Container>
  );
};

export default Print;
