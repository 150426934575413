import Modal from "components/Modal";
import { useState } from "react";
import FileInput from "components/FileInput";
import ImageCropper from "components/ImageCropper";
import FirebaseStorageService from "libs/firebase/FirebaseStorageService";
import { useUpdateEvent } from "services/events";
import { ProgressContainer, Container, UploadingContainer } from "./styles";
import { FormattedMessage } from "react-intl";
import Spinner from "components/Spinner";

const BannerSection = ({ event }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState("");
  const [croppedImage, setCroppedImage] = useState("");
  const [uploadProgress, setUploadProgress] = useState(-1);
  const { mutate: update } = useUpdateEvent();

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  // Invoked when new image file is selected
  const onImageSelected = (selectedImg) => {
    setUploadedImage(selectedImg);
    onOpenModal();
  };

  // Generating Cropped Image When Done Button Clicked
  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = 800;
    canvasEle.height = 450;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = uploadedImage;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        canvasEle.width,
        canvasEle.height
      );

      canvasEle.toBlob(
        async (blob) => {
          const file = new File([blob], "banner.png", {
            type: "image/png",
            lastModified: Date.now(),
          });
          try {
            const downloadUrl = await FirebaseStorageService.uploadFile(
              file,
              `events/${event.id}-${Date.now()}.png`,
              setUploadProgress
            );

            if (event.banner) {
              await FirebaseStorageService.deleteFile(event.banner);
            }
            setCroppedImage(downloadUrl);

            update(
              { ...event, banner: downloadUrl },
              {
                onSuccess: () => {
                  setUploadProgress(-1);
                },
              }
            );
          } catch (error) {
            setUploadProgress(-1);
            const errorMsg = `onCropDone.onload [${JSON.stringify(error)}]`;
            console.log(errorMsg);
          }
        },
        "image/png",
        1
      );

      onCloseModal();
    };
  };

  // Handle Cancel Button Click
  const onCropCancel = () => {
    onCloseModal();
    if (!croppedImage) {
      setUploadedImage(null);
    }
  };

  return (
    <Container>
      {/* Check if there is a banner */}
      {uploadProgress === 100 ? (
        <UploadingContainer>
          <h3>
            <FormattedMessage id="app.finishingUpload" />{" "}
          </h3>
          <Spinner color="#f76c6f" />
        </UploadingContainer>
      ) : // Show upload progress if there is one
      uploadProgress > -1 && uploadProgress !== 100 ? (
        <ProgressContainer>
          <progress id="file" value={uploadProgress} max="100">
            {uploadProgress}%
          </progress>
          <span>{uploadProgress}%</span>
        </ProgressContainer>
      ) : croppedImage ? (
        // After the upload progress show the banner image if there is one
        <FileInput
          image={croppedImage}
          setImage={setUploadedImage}
          onImageSelected={onImageSelected}
        />
      ) : event.banner ? (
        // Show the banner image if there is one saved in the database
        <FileInput
          image={event.banner}
          setImage={setUploadedImage}
          onImageSelected={onImageSelected}
        />
      ) : (
        // Show the upload button if there is no banner, with banner image as default
        <FileInput
          setImage={setUploadedImage}
          onImageSelected={onImageSelected}
        />
      )}
      <Modal
        isOpen={isModalOpen}
        handleClose={onCloseModal}
        title={<FormattedMessage id="app.adjustImage" />}
      >
        <ImageCropper
          image={uploadedImage}
          onCropDone={onCropDone}
          onCropCancel={onCropCancel}
        />
      </Modal>
    </Container>
  );
};

export default BannerSection;
