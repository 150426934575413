import { Container } from "./styles";

const Logo = ({ color, fontBase }) => {
  return (
    <Container color={color} fontBase={fontBase}>
      Cápsula do <br />
      <span>Tempo</span>
    </Container>
  );
};

export default Logo;
