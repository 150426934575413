export const formatValue = (market, value) => {
  switch (market.toLowerCase()) {
    case "usd":
      return value.toLocaleString("en-us", {
        style: "currency",
        currency: "USD",
        currencyDisplay: "code",
      });
    case "brl":
      return value.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    case "eur":
      return value.toLocaleString("en-us", {
        style: "currency",
        currency: "EUR",
      });
    default:
      return value.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
  }
};

/**
 * Replaced to FormattedDate from react-intl
 */
export const formatDate = (market, date) => {
  if (!market) {
    market = "BRL";
  }
  const newDate = new Date(date);
  switch (market) {
    case "USD":
      return newDate.toLocaleDateString("en-us", {
        timeZone: "UTC",
      });
    case "BRL":
      return newDate.toLocaleDateString("pt-br", {
        timeZone: "UTC",
      });
    case "EUR":
      return newDate.toLocaleDateString("en-us", {
        timeZone: "UTC",
      });
    default:
      return newDate.toLocaleDateString("pt-br", {
        timeZone: "UTC",
      });
  }
};

export const parseVimeoId = (uri) => {
  const vimeoId = uri.replace("/videos/", "");
  return vimeoId;
};

export const getRemainingDays = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const releaseDate = new Date(date);
  releaseDate.setHours(0, 0, 0, 0);

  const remainingDays = Math.ceil(
    (releaseDate.getTime() - today.getTime()) / (1000 * 3600 * 24)
  );

  return remainingDays;
};

export const convertToDate = (date) => {
  if (date) {
    if (date instanceof Date) return date;

    const newDate = new Date();
    const [year, month, day] = date.split("-");
    newDate.setHours(0, 0, 0, 0);
    newDate.setDate(day);
    newDate.setMonth(month - 1);
    newDate.setFullYear(year);

    return newDate;
  }
  return null;
};

export const dateToString = (date) => {
  //if date is not a Date, return date
  if (!(date instanceof Date)) return date;
  console.log("dateToString date", date);
  if (date) {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);

    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const resultDay = day < 10 ? `0${day}` : day;
    const resultMonth = month < 10 ? `0${month}` : month;

    return `${year}-${resultMonth}-${resultDay}`;
  }
  return null;
};
